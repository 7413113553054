.NewPostPageWrapper {
    margin-top: 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.editorWrapper {
    width: 60vw;
    margin-bottom: 20px;
}

.thirdRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
}

.previewImageWrapper {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.imagePreview {
    max-width: 500px;
}

.flexRow {
    display: flex;
    justify-content: space-between;
}