.FooterWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    margin: 0 2rem;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text {
    text-decoration: none;
    color: inherit;
}

.firstRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}


.secondRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

@media (max-width: 440px) {
    .firstRow {
        flex-direction: column;
        align-items: center;
    }
}

.icon {
    margin: 0 30px;
    cursor: pointer;
}