.blog-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 62vh;
}

.addButton {
    width: 100%;
    text-align: center;
}