.headerWrapper{
    width: 100%;
    padding-left: 2.4rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.modalButtonsWrapper {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
