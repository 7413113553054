.pageWrapper {
    width: 100vw;
    height: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

.layoutWrapper {
    padding-top: 8.5rem;
    width: 100%;
    height: 100%;
}

@media (max-width: 899px) {
    .layoutWrapper {
        padding-top: 7.5rem;
    }
}