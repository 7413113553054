.adminPageWrapper{
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
}

.inputs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 45px 0 30px 0;
}