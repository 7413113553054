.serviceItemWrapper {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4.5rem;
}

.serviceImage {
    width: 40%;
    min-width: 274px;
}

.textContainer {
    margin-left: 2rem;
    width: 60%;
    white-space: pre-line;
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

@media (max-width: 1200px) {
    .serviceItemWrapper {
        width: 80%;
    }    
}

@media (max-width: 900px) {
    .serviceItemWrapper {
        width: 90%;
    }    
}

@media (max-width: 650px) {
    .serviceItemWrapper {
        flex-direction: column;
    }
    
    .serviceImage {
        width: 97%;
    }
    
    .textContainer {
        width: 97%;
        margin-left: 0;
    }
}

