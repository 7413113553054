
.Toolbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 95;
}

.logo {
    height: 100px;
    margin: 0.15rem 0;
    cursor: pointer;
}

.logoHorizontal {
    height: 72px;
}