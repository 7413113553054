.postWrapper {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.postImage {
    width: 56%;
}

.preview {
    padding: 1rem;
    margin-top: 1rem;
    width: 50%;
}

@media (max-width: 1000px) {
    .preview {
        width: 60%;
    }

    .postImage {
        width: 66%;
    }
}

@media (max-width: 900px) {
    .preview {
        width: 100%;
    }

    .postImage {
        width: 100%;
    }
}