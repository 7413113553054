.homeWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 2rem;
    margin-top: -48px;
    min-height: 55vh;
}

.homeImage {
    width: 100vw;
    height: 40vw;
    background-image: url("../../assets/images/home-foto.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.servicesSection {
    padding-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
}

.serviceItem {
    text-align: center;
    width: 55%;
}

.bio {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bioImage {
    max-width: 50rem;
    width: 100%;
    object-fit: contain;
}

.buttonsWrapper {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1610px) {
    .serviceItem {
        width: 60%;
    }
}

@media (max-width: 1470px) {
    .serviceItem {
        width: 65%;
    }
}

@media (max-width: 1360px) {
    .serviceItem {
        width: 70%;
    }
}

@media (max-width: 1270px) {
    .serviceItem {
        width: 100%;
    }
}


@media (max-width: 710px) {
    .buttonsWrapper {
        flex-direction: column;
    }
}