.cardWrapper {
    width: 345px;
    height: 345px;
    margin: 3.2rem;
}

.modalButtonsWrapper {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
}

@media (max-width: 1000px) {
    .cardWrapper {
        width: 600px;
        height: 500px;
    }
}

@media (max-width: 700px) {
    .cardWrapper {
        width: 400px;
        height: 400px;
    }
}

@media (max-width: 500px) {
    .cardWrapper {
        width: 345px;
        height: 345px;
    }
}