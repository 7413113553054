.wrapper-class {
    width: 100%;
    height: 100%;
}

.editor-class {
    padding: 1rem;
    height: 38rem;
    border: 1px solid #ccc;
}
.toolbar-class {
    border: 1px solid #ccc;
}

.preview {
    padding: 1rem;
    margin-top: 1rem;
}